import { inspectionTypes  } from './inspection.types';


    const INITIAL_STATE = {
        current_step: 0,
        is_loading: false,
        is_approving: false,
        getting_stats: false,
        dash_stats: {},
        inspection_purposes: null,
        inspection_points: null,
        inspection_fields: null,
        inspection_sheet: null,
        vehicle_purposes: null,
        inspection_grades: null,
        draft_inspections: null,
        new_vehicle_inspection: null,
        inspection_sheet_details: {},
        resuming_draft: false,
        selected: {
          related_vehicle_purpose:null,
          related_inspection_point:null,
          related_inspection_station: null,
          inspection_purpose:null,
          inspection_point: null,
          vehicle_type: null,
          inspection_station: null,
          plate_no: null,
          registered_owner: null,
          vehicle_purpose: null,
        },
        sidebar:{
          vehicle_type: "vehicle type",
          vehicle_capacity: "capicity",
          vehicle_purpose: "purpose",

        },
        error: ""
    };

    const reducer = (state = INITIAL_STATE, action) => {

        switch (action.type) {
          case inspectionTypes.GET_VEHICLE_INSPECTION_PURPOSE_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_PURPOSE_SUCCESS:
            return {
              ...state, is_loading: false, inspection_purposes:action.payload
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_PURPOSE_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.GET_VEHICLE_INSPECTION_POINT_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_POINT_SUCCESS:
            return {
              ...state, is_loading: false, inspection_points:action.payload
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_POINT_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.GET_VEHICLE_INSPECTION_FIELDS_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_FIELDS_SUCCESS:
            return {
              ...state, is_loading: false, inspection_fields:action.payload
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_FIELDS_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.GET_VEHICLE_PURPOSE_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_VEHICLE_PURPOSE_SUCCESS:
            return {
              ...state, is_loading: false, vehicle_purposes:action.payload
            };
          case inspectionTypes.GET_VEHICLE_PURPOSE_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.GET_VEHICLE_INSPECTION_SHEET_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_SHEET_SUCCESS:

            return {
              ...state, is_loading: false, inspection_sheet:action.payload['sheet']
            };
          case inspectionTypes.GET_VEHICLE_INSPECTION_SHEET_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.GET_INSPECTION_GRADES_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.GET_INSPECTION_GRADES_SUCCESS:
            return {
              ...state, is_loading: false, inspection_grades:action.payload
            };
          case inspectionTypes.GET_INSPECTION_GRADES_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.UPDATE_SELECTED_STARTED:
            return {
              ...state, updating_user_input: true,
            };
          case inspectionTypes.UPDATE_SELECTED_SUCCESS:
            state["selected"][action.payload.name] = action.payload.value
            state["error"][action.payload.name] = action.payload.err
            return {
              ...state,
            };
          case inspectionTypes.UPDATE_SELECTED_FAILED:
            return {
              ...state, errors: action.payload
            };

          case inspectionTypes.CREATE_VEHICLE_INSPECTION_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.CREATE_VEHICLE_INSPECTION_SUCCESS:
            return {
              ...state, is_loading: false, new_vehicle_inspection: action.payload, inspection_sheet: null
            };
          case inspectionTypes.CREATE_VEHICLE_INSPECTION_FAILED:
            return {
              ...state, is_loading: false, error:action.payload.data
            };

          case inspectionTypes.GET_DRAFT_INSPECTIONS_STARTED:
            return {
              ...state, is_loading: true,getting_stats:true
            };
          case inspectionTypes.GET_DRAFT_INSPECTIONS_SUCCESS:
            return {
              ...state, is_loading: false,getting_stats:false, draft_inspections:action.payload
            };
          case inspectionTypes.GET_DRAFT_INSPECTIONS_FAILED:
            return {
              ...state, is_loading: false, getting_stats:false, draft_inspections: null, error:action.payload.data
            };

          case inspectionTypes.COMPLETE_VEHICLE_INSPECTION_ITEMS_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.COMPLETE_VEHICLE_INSPECTION_ITEMS_SUCCESS:
            return {
              ...state, is_loading: false
            };
          case inspectionTypes.COMPLETE_VEHICLE_INSPECTION_ITEMS_FAILED:
            return {
              ...state, is_loading: false, error:action.payload
            };

          case inspectionTypes.SAVE_VEHICLE_INSPECTION_DRAFT_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.SAVE_VEHICLE_INSPECTION_DRAFT_SUCCESS:
            return {
              ...state, is_loading: false
            };
          case inspectionTypes.SAVE_VEHICLE_INSPECTION_DRAFT_FAILED:
            return {
              ...state, is_loading: false, error:action.payload
            };

          case inspectionTypes.RETRIEVE_DRAFT_STARTED:
            return {
              ...state, is_loading: true,
            };
          case inspectionTypes.RETRIEVE_DRAFT_SUCCESS:
            return {
              ...state, is_loading: false, 
              resuming_draft: true,
              inspection_sheet:action.payload['sheet'],
              inspection_sheet_details: action.payload['details'],
              new_vehicle_inspection:{...state.new_vehicle_inspection, id:action.payload['details']['id']}
            };
          case inspectionTypes.RETRIEVE_DRAFT_FAILED:
            return {
              ...state, is_loading: false, error:action.payload
            };

          case inspectionTypes.UPDATE_STEP_PROGRESS_STARTED:
            return { ...state, };
          case inspectionTypes.UPDATE_STEP_PROGRESS_SUCCESS:
            return { ...state, current_step: action.payload};
          case inspectionTypes.UPDATE_STEP_PROGRESS_FAILED:
            return {
              ...state, current_step: 0, error:action.payload
            };

          case inspectionTypes.GET_DASHBOARD_STATS_STARTED:
            return {
              ...state, getting_stats: true,
            };
          case inspectionTypes.GET_DASHBOARD_STATS_SUCCESS:
            return {
              ...state, getting_stats: false, dash_stats: { ...action.payload },
            };
          case inspectionTypes.GET_DASHBOARD_STATS_FAILED:
            return {
              ...state, getting_stats: false, errors: action.payload
            };

          case inspectionTypes.APPROVE_VEHICLE_INSPECTION_DRAFT_STARTED:
            return {
              ...state, is_approving: true,
            };
          case inspectionTypes.APPROVE_VEHICLE_INSPECTION_DRAFT_SUCCESS:
            return {
              ...state, is_approving: false
            };
          case inspectionTypes.APPROVE_VEHICLE_INSPECTION_DRAFT_FAILED:
            return {
              ...state, is_approving: false, error:action.payload
            };
          default: return state;

        }

    };

    export default reducer;